import { useState } from "react";
import { useEffect, useRef } from 'react';
import Button from "./Button";

// Image
import Menu from '../../assets/imgs/menu.svg';

export default function Sidebar({ children,title,save, cancel,onClickSave,onClickCancel }) {
    const ref = useRef(null);
    //abrir menu
    const [open, setOpen] = useState(false);
    //abrir menu
    const toggleMenu = (isOpen) => {
        setOpen(isOpen);
    }

    useEffect(() => {
        function handleClickOutside(event) {
    
          if ((ref.current && !ref.current.contains(event.target))) {
            setOpen(false);
          }
        }
        
        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [ref]);


    return (
        <>
        <div ref={ref} id="mySidenav" className="sidenav" style={open? {width: '350px'} : {width: '0px'}} >
            <div className="sidenav-top">
                    { 
                       title && <h4>{title}</h4>
                    }
                <a href="#" className="close" onClick={toggleMenu.bind(this, !open)}>&times;</a>

            </div>
            <div className="sidenav-contenido">
                { children }
                
            </div>

            <div className="sidenav-bottom">
                   

                    {
                        cancel && <Button className="caution" onClick={onClickCancel ? onClickCancel : toggleMenu.bind(this, !open)}>{cancel}</Button>
                    }

                    {
                        save && <Button className="primary" onClick={onClickSave? onClickSave : null}>{save}</Button>
                    }
            </div>


        </div>
        
        <div>
            {/* <span style={{fontSize:30,cursor:"pointer"}} onClick={() => setOpen(!open)}> */}
            <span style={{fontSize:30,cursor:"pointer"}} onClick={toggleMenu.bind(this, !open)}>
                <img src={Menu} alt="Menu" width='30px' height='30px' />
            </span>
        </div>
        </>
       

    );
}