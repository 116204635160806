// Router
import { Navigate, Route, Routes } from "react-router-dom";

// Pages
import Colores from "../pages/Colores";
import Tipografia from "../pages/Tipografia";

export default function FundacionesRoutes() {
    
    return (
        <Routes>
            <Route path="/colores" element={ <Colores /> } />
            <Route path="/tipografia" element={ <Tipografia /> } />
            
            <Route path='/' element={<Navigate to={'colores'} />} />
        </Routes>
    );
}