export default function Input({ children, type, placeholder, className,onChange,id,value,showArrow }) {
    
    switch (type) {
        case 'search':

            return (
                <>
                    <div className="control">
                        <input id={id} onChange={onChange} className={`input ${className !== undefined ? className : ''}`} type="text" placeholder={placeholder !== undefined ? placeholder : 'Buscar...'} />
                        <span className="icon"><svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="magnifying-glass" class="svg-inline--fa fa-magnifying-glass " role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path fill="currentColor" d="M500.3 443.7l-119.7-119.7c27.22-40.41 40.65-90.9 33.46-144.7C401.8 87.79 326.8 13.32 235.2 1.723C99.01-15.51-15.51 99.01 1.724 235.2c11.6 91.64 86.08 166.7 177.6 178.9c53.8 7.189 104.3-6.236 144.7-33.46l119.7 119.7c15.62 15.62 40.95 15.62 56.57 0C515.9 484.7 515.9 459.3 500.3 443.7zM79.1 208c0-70.58 57.42-128 128-128s128 57.42 128 128c0 70.58-57.42 128-128 128S79.1 278.6 79.1 208z"></path></svg></span>
                    </div>
                </>
                    
                
            );

            //return <input id={id} onChange={onChange} className={`search ${className !== undefined ? className : ''}`} type="text" placeholder={placeholder !== undefined ? placeholder : 'Buscar...'}  /> //icon search 

    
        case 'number':
            return <input id={id} onChange={onChange} className={showArrow?'number ':'number-hidden-spinner ' +` ${className !== undefined ? className : ''}`} type="number" placeholder={placeholder !== undefined ? placeholder : '0'} value={value} /> //icon search
        case 'date':
            return <input id={id} onChange={onChange} className={`date ${className !== undefined ? className : ''}`} type="date" placeholder={placeholder !== undefined ? placeholder : '0'}  value={value !== undefined ? value : ''} />
        
        case 'time':
            return <input id={id} onChange={onChange} className={`time ${className !== undefined ? className : ''}`} type="time" placeholder={placeholder !== undefined ? placeholder : '0'}  value={value !== undefined ? value : ''} />

        case 'datetime-local':
            return <input id={id} onChange={onChange} className={`datetime-local ${className !== undefined ? className : ''}`} type="datetime-local" placeholder={placeholder !== undefined ? placeholder : '0'}  value={value !== undefined ? value : ''} />

        default:
            return <input type="text" />
    }
}