import { useRef } from "react";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";

export default function GridStatus({ data, textAlign, id }) {

    const navigate = useNavigate()
    const gridContent =  useRef()

    useEffect(() => {
        let width = [];
        let height = [];
        let maxWidth = 0;
        let maxHeight = 0;

        document.querySelectorAll(`#${id} .gs-column`).forEach(column => {
            if (!width.includes(column.clientWidth)) {
                width.push(column.clientWidth);
            }
            if (!height.includes(column.clientHeight)) {
                height.push(column.clientHeight);
            }
        });

        maxWidth = Math.max(...width);
        maxHeight = Math.max(...height);

        document.querySelectorAll(`#${id} .gs-column`).forEach(column => {
            column.style.width = `${maxWidth}px`
            column.style.height = `${maxHeight}px`
        });
    }, [])
    

    return (
        <div className="gs-content" id={id}>
            {data.map((row, index) => {
                return (
                    <div key={index} className="gs-row">
                        {row.map((column, index) => {
                            return (
                                <div key={index} className={`gs-column ${textAlign != undefined ? `text-${textAlign}` : ''} ${column.link != undefined && column.link != '' ? 'cursor-pointer' : ''} ${column.status ?? ''}`} onClick={() => navigate(column.link)}>
                                    <div className="text">{column.value ?? 'N/A'}</div>
                                </div>
                            );
                        })}
                    </div>
                );
            })}
        </div>
    );
}