export default function Modal({ children, open, onClose, titulo, size }) {
    return (
        <div className={`modal ${open ? 'active' : ''}`}>
            <div className='modal-container' onClick={() => onClose()}></div>
            <div className="modal-content" style={{ maxWidth: `${size}px` }}>
                <div className={`header ${titulo != undefined ?  'just-sb' : 'just-e'}`}>
                    {titulo != undefined ? <h2>{titulo}</h2> : <></>}
                    <svg className="icon" strokeWidth="0" viewBox="0 0 512 512" height="1rem" width="1rem" xmlns="http://www.w3.org/2000/svg" onClick={() => onClose()}>
                        <path d="M256 8C119 8 8 119 8 256s111 248 248 248 248-111 248-248S393 8 256 8zm0 448c-110.5 0-200-89.5-200-200S145.5 56 256 56s200 89.5 200 200-89.5 200-200 200zm101.8-262.2L295.6 256l62.2 62.2c4.7 4.7 4.7 12.3 0 17l-22.6 22.6c-4.7 4.7-12.3 4.7-17 0L256 295.6l-62.2 62.2c-4.7 4.7-12.3 4.7-17 0l-22.6-22.6c-4.7-4.7-4.7-12.3 0-17l62.2-62.2-62.2-62.2c-4.7-4.7-4.7-12.3 0-17l22.6-22.6c4.7-4.7 12.3-4.7 17 0l62.2 62.2 62.2-62.2c4.7-4.7 12.3-4.7 17 0l22.6 22.6c4.7 4.7 4.7 12.3 0 17z"></path>
                    </svg>
                    {/* <svg className="icon" strokeWidth="0" viewBox="0 0 16 16" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg">
                        <path fill-rule="evenodd" clip-rule="evenodd" d="M7.116 8l-4.558 4.558.884.884L8 8.884l4.558 4.558.884-.884L8.884 8l4.558-4.558-.884-.884L8 7.116 3.442 2.558l-.884.884L7.116 8z"></path>
                    </svg> */}
                </div>
                <div className="body">{children}</div>
            </div>
        </div>
    );
}