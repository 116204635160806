
// Layout
import Main from "../../../components/layout/Main"

// Component
import ProgressBar from "../../../components/elements/ProgressBar"
import BoxCode from "../../../components/elements/BoxCode"
import { useEffect, useState } from "react";


export default function ProgressBars() {

    const [isSuscced, setIsSuscced] = useState(false);
    const [isError, setIsError] = useState(false);

    useEffect(() => {
        loading();
    }, [])

    async function loading() {
        setIsError(await fail());
        setIsSuscced(await load());
    }

    async function load() {
        await new Promise(resolve => {
            setTimeout(() => {
                resolve()
            }, 1000);
        });
        return true
    }

    async function fail() {
        try {
            await new Promise((resolve, reject) => {
                setTimeout(() => {
                    reject('Error')
                }, 2000);
            })   
        } catch (err) {
            return true;
        }
    }

    
    

    const code1 = `
import ProgressBar from "../../../components/elements/ProgressBar";

export default function ProgressBarExample() {
    return (
        <ProgressBar></ProgressBar>
    );
}`;

    const code2 = `
import ProgressBar from "../../../components/elements/ProgressBar";

export default function ProgressBarExample() {
    return (
        <ProgressBar color='#1f8ee7' height='15'></ProgressBar>
    );
}`;

    const code3 = `
import ProgressBar from "../../../components/elements/ProgressBar";

export default function ProgressBarExample() {
    return (
        <>
                    <ProgressBar></ProgressBar>
                    <ProgressBar color='important'></ProgressBar>
                    <ProgressBar color='primary'></ProgressBar>
                    <ProgressBar color='caution'></ProgressBar>
                    <ProgressBar color='#1f8ee7'></ProgressBar>
                    <ProgressBar color='rgba(115, 0, 163, 0.8)'></ProgressBar>
            </>
    );
}`;

    const code4 = `
import ProgressBar from "../../../components/elements/ProgressBar";

export default function ProgressBarExample() {
    return (
        <ProgressBar type='porcentaje' color='primary' height='6'></ProgressBar>
    );
}`;

    const code5 = `
import ProgressBar from "../../../components/elements/ProgressBar";
import { useEffect, useState } from "react";

export default function ProgressBarExample() {

    const [isSuscced, setIsSuscced] = useState(false);

    useEffect(() => {
        loading();
    }, [])

    async function loading() {
        setIsSuscced(await load());
    }

    async function load() {
        await new Promise(resolve => {
            setTimeout(() => {
                resolve()
            }, 1000);
        });
        return true
    }

    return (
        <ProgressBar type='porcentaje' color='primary' height='6' isSuscced={isSuscced}></ProgressBar>
    );
}`;

    const code6 = `
import ProgressBar from "../../../components/elements/ProgressBar";
import { useEffect, useState } from "react";

export default function ProgressBarExample() {

    const [isError, setIsError] = useState(false);

    useEffect(() => {
        loading();
    }, [])

    async function loading() {
        setIsError(await fail());
    }

    async function fail() {
        try {
            await new Promise((resolve, reject) => {
                setTimeout(() => {
                    reject('Error')
                }, 2000);
            })   
        } catch (err) {
            return true;
        }
    }
    
    return (
        <ProgressBar type='porcentaje' color='primary' height='6' isError={isError} time={150}></ProgressBar>
    );
}`;

    return (
        <Main>
            <div className="section elementos">
                <h5 className="c-green">Componentes</h5>
                <h2>Elementos</h2>

                <BoxCode title='Progress Bar' code={code1}>
                    <ProgressBar color='primary'></ProgressBar>
                </BoxCode>

                <BoxCode title='Progress Bar Height' code={code2}>
                    <ProgressBar color='#1f8ee7' height='15'></ProgressBar>
                </BoxCode>

                <BoxCode title='Progress Bar Colors' classBox='col' code={code3}>
                    <ProgressBar></ProgressBar>
                    <ProgressBar color='important'></ProgressBar>
                    <ProgressBar color='primary'></ProgressBar>
                    <ProgressBar color='caution'></ProgressBar>
                    <ProgressBar color='#1f8ee7'></ProgressBar>
                    <ProgressBar color='rgba(115, 0, 163, 0.8)'></ProgressBar>
                </BoxCode>

                <BoxCode title='Progress Bar Porcentaje' code={code4}>
                    <ProgressBar type='porcentaje' color='primary' height='6'></ProgressBar>
                </BoxCode>

                <BoxCode title='Progress Bar Suscced' code={code5}>
                    <ProgressBar type='porcentaje' color='primary' height='6' isSuscced={isSuscced}></ProgressBar>
                </BoxCode>

                <BoxCode title='Progress Bar Error' code={code6}>
                    <ProgressBar type='porcentaje' color='primary' height='6' isError={isError} time={150}></ProgressBar>
                </BoxCode>
            </div>
        </Main>
    )
}