
import { useState } from 'react';
import { NumericFormat } from 'react-number-format';

export default function Col({ children, className, size, order, filter, filterData, type, actionOrder, actionFilter, priority, max }) {

    const [isOrder, setIsOrder] = useState(false)

    function getThisTypes() {
        switch (type) {
            case 'icon':
                return getElement(children, 'icon');

            case 'btn':
                return getElement(children, 'btn');

            case 'money':
                return getElement(new Intl.NumberFormat('es-MX', {style: 'currency', currency: 'MXN', minimumFractionDigits: 0}).format(children.slice(0, max)), 'money');

            case 'numero':
                return getElement(<NumericFormat value={children.slice(0, max)} displayType='text' thousandSeparator={true} />, 'numero');

            default:
                return getElement(typeof children == 'string' ? children.slice(0, max) : children, '');
        }
    }

    function getElement(children) {
        if (order) {
            return (
                <td style={{ minWidth: size != undefined ? `${size}px` : 'auto' }} className={`cursor-pointer ${priority != undefined ? priority : 'baja'} ${type !== undefined ? type : ''} ${className !== undefined ? className : ''}`} onClick={() => {actionOrder(); setIsOrder(!isOrder)}}>
                    <div className='td-content'>
                        {children}
                        <div className="arrow" style={ isOrder ? {transform: 'rotate(180deg) translateY(50%)'} : {}}></div>
                        {/* <div className="arrow" style={ open ? {transform: 'rotate(180deg) translateY(50%)'} : {}}></div> */}
                        {/* <svg className='icon' stroke="currentColor" strokeWidth="0" viewBox="0 0 24 24" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg">
                            <path d="M16.293 9.293 12 13.586 7.707 9.293l-1.414 1.414L12 16.414l5.707-5.707z"></path>
                        </svg> */}
                    </div>
                </td>
            );
        }

        return <td style={{ minWidth: size != undefined ? `${size}px` : 'auto' }} className={`${className !== undefined ? className : ''} ${priority != undefined ? priority : 'baja'} ${type !== undefined ? type : ''}`}>{children}</td>;
    }

    return getThisTypes();
    // return <td>{children}</td>;
}