
// Layout
import Main from "../../../components/layout/Main";

// Component
import Nav from "../../../components/elements/Nav";
import BoxCode from "../../../components/elements/BoxCode";

// Image
import Logo from '../../../assets/imgs/logo-horizontal.svg';

const Link = [{
    link: '/fundaciones',
    title: 'Fundaciones'
},
{
    link: '/componentes',
    title: 'Componentes'
}];

export default function Navbar() {

    const code1 = `
import Nav from "../../../components/elements/Nav";

export default function NavbarExample() {
    return (
        <Nav />
    );
}`;

    const code2 = `
import Nav from "../../../components/elements/Nav";

const Link = [{
    link: '/fundaciones',
    title: 'Fundaciones'
},
{
    link: '/componentes',
    title: 'Componentes'
}];

export default function NavbarExample() {
    return (
        <Nav links={Link} />
    );
}`;

    const code3 = `
import Nav from "../../../components/elements/Nav";
import Logo from '../../../assets/imgs/logo-horizontal.svg';

export default function NavbarExample() {
    return (
        <Nav logo={Logo} />
    );
}`;

    return (
        <Main>
            <div className="section elementos">
                <h5 className="c-green">Componentes</h5>
                <h2>Elementos</h2>

                <BoxCode title='Navbar' classBox='gray6' code={code1}>
                    <Nav navClass='position-relative' />
                </BoxCode>

                <BoxCode title='Navbar + Links' classBox='gray6' code={code2}>
                    <Nav navClass='position-relative' links={Link} />
                </BoxCode>

                <BoxCode title='Navbar + Logo' classBox='gray6' code={code3}>
                    <Nav navClass='position-relative' logo={Logo} />
                </BoxCode>
            </div>
        </Main>
    );
}
