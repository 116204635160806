import { useEffect, useState } from "react";


export default function Row({ children, type, head, data, className}) {

    const [isColapsed, setIsColapsed] = useState(true);

    if (type?.toUpperCase() == 'HEADER') {
        return (
            <thead className="table-header">
                <tr className={`table-row ${className !== undefined ? className : ''}`}>
                    <td></td>
                    {children}
                </tr>
            </thead>
        )
    } else {
        return (
            <>
                <tr className={`table-row ${className !== undefined ? className : ''}`}>
                    <td onClick={() => setIsColapsed(!isColapsed)}>
                        <svg className="icon" stroke="currentColor" strokeWidth="0" viewBox="0 0 24 24" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg">
                            <path d="M12 2C6.486 2 2 6.486 2 12s4.486 10 10 10 10-4.486 10-10S17.514 2 12 2zm0 18c-4.411 0-8-3.589-8-8s3.589-8 8-8 8 3.589 8 8-3.589 8-8 8z"></path>
                            <path d="M12 13.586 7.707 9.293l-1.414 1.414L12 16.414l5.707-5.707-1.414-1.414z"></path>
                        </svg>
                    </td>
                    {children}
                </tr>
                <tr className={`table-mobile ${isColapsed ? 'none' : ''}`}>
                    <td colSpan={head.length}>
                        {head.map((head, index) => {
                            return (
                                <div key={index} className={`container`}>
                                    <span>{head}</span>
                                    <span className={`${children[index]?.props?.type}`}>{data[index]}</span>
                                </div>
                            )
                        })}
                    </td>
                </tr>
            </>
        )
    }

}