// Layout
import Main from "../../../components/layout/Main";

export default function Colores() {
    return (
        <Main>
            <div className='section color'>
                <h5 className='c-green'>Fundaciones</h5>
                <h2>Colores</h2>

                <h3>Marca</h3>
                <div className='colorbox box green c-white p20'>
                    <h4 className='c-white'>Verde Wiwi</h4>
                    <span>#71d44c</span>
                </div>

                <h3>Funcionales</h3>
                <div className='colorbox box gray c-white p20'>
                    <h4 className='c-white'>Gris Wiwi</h4>
                    <span className='c-white'>#606060</span>
                </div>
                <div className='colorbox box gray2 c-white p20'>
                    <h4 className='c-white'>Gris2 Wiwi</h4>
                    <span>#eee</span>
                </div>
                <div className='colorbox box gray3 c-white p20'>
                    <h4 className='c-white'>Gris3 Wiwi</h4>
                    <span>#f1f1f1</span>
                </div>
                <div className='colorbox box black c-white p20'>
                    <h4 className='c-white'>Negro Wiwi</h4>
                    <span>#111</span>
                </div>
                <div className='colorbox box white c-white p20'>
                    <h4>Blanco Wiwi</h4>
                    <span>#ffffff</span>
                </div>
            </div>
        </Main>
    );
}