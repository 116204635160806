

import BoxCode from "../../../components/elements/BoxCode";
import GridStatus from "../../../components/elements/GridStatus";
import Main from "../../../components/layout/Main";

const data = [
[
    {
        value: '1',
        status: 'off',
        link: '/fundaciones'
    },
    {
        value: '2',
        status: 'on',
        link: '/fundaciones'
    },
    {
        value: '3',
        status: 'ok',
        link: '/fundaciones'
    },
    {
        value: '4',
        status: 'warnig',
        link: '/fundaciones'
    },
    {
        value: '5',
        status: 'error',
        link: '/fundaciones'
    }
],
[
    {
        value: '6',
        status: 'ok',
        link: '/fundaciones'
    },
    {
        value: '7',
        status: 'on',
        link: '/fundaciones'
    },
    {
        value: '8',
        status: 'ok',
        link: '/fundaciones'
    },
    {
        value: '9',
        status: 'ok',
        link: '/fundaciones'
    },
    {
        value: '10',
        status: 'off',
        link: '/fundaciones'
    }
],
[
    {
        value: '11',
        status: 'ok',
        link: '/fundaciones'
    },
    {
        value: '12',
        status: 'on',
        link: '/fundaciones'
    },
    {
        value: '13',
        status: 'warnig',
        link: '/fundaciones'
    },
    {
        value: '14',
        status: 'error',
        link: '/fundaciones'
    },
    {
        value: '15',
        status: 'on',
        link: '/fundaciones'
    }
],
[
    {
        value: '16',
        status: 'on',
        link: '/fundaciones'
    },
    {
        value: '17',
        status: 'on',
        link: '/fundaciones'
    },
    {
        value: '18',
        status: 'off',
        link: '/fundaciones'
    },
    {
        value: '19',
        status: 'on',
        link: '/fundaciones'
    },
    {
        value: '20',
        status: 'ok',
        link: '/fundaciones'
    }
],
[
    {
        value: '21',
        status: 'on',
        link: '/fundaciones'
    },
    {
        value: '22',
        status: 'on',
        link: '/fundaciones'
    },
    {
        value: '23',
        status: 'off',
        link: '/fundaciones'
    },
    {
        value: '24',
        status: 'on',
        link: '/fundaciones'
    },
    {
        value: '25',
        status: 'ok',
        link: '/fundaciones'
    }
]];

const data2 = [
    [
        {
            value: '1',
            status: 'off',
            link: '/fundaciones'
        },
        {
            value: '2',
            status: 'on',
            link: '/fundaciones'
        },
        {
            value: '3',
            status: 'ok',
            link: '/fundaciones'
        },
        {
            value: '4',
            status: 'warnig',
            link: '/fundaciones'
        },
        {
            value: '5',
            status: 'error',
            link: '/fundaciones'
        }
    ],
    [
        {
            value: '6',
            status: 'ok',
            link: '/fundaciones'
        },
        {
            value: '7',
            status: 'on',
            link: '/fundaciones'
        },
        {
            value: '8',
            status: 'ok',
            link: '/fundaciones'
        },
        {
            value: '9',
            status: 'ok',
            link: '/fundaciones'
        },
        {
            value: '10',
            status: 'off',
            link: '/fundaciones'
        }
    ]
];

const data3 = [
    [
        {
            value: 'Server apagado',
            status: 'off',
            link: '/fundaciones'
        },
        {
            value: 'Server encendido',
            status: 'on',
            link: '/fundaciones'
        },
        {
            value: 'Server Funcionando',
            status: 'ok',
            link: '/fundaciones'
        },
        {
            value: 'Server warnig 500',
            status: 'warnig',
            link: '/fundaciones'
        },
        {
            value: 'Error server 404',
            status: 'error',
            link: '/fundaciones'
        }
    ],
    [
        {
            value: 'Server Funcionando',
            status: 'ok',
            link: '/fundaciones'
        },
        {
            value: 'Server encendido',
            status: 'on',
            link: '/fundaciones'
        },
        {
            value: 'Server Funcionando',
            status: 'ok',
            link: '/fundaciones'
        },
        {
            value: 'Server Funcionando',
            status: 'ok',
            link: '/fundaciones'
        },
        {
            value: 'Server apagado',
            status: 'off',
            link: '/fundaciones'
        }
    ]
];

const code = `
import GridStatus from "../../../components/elements/GridStatus";

const data = [
[
    {
        value: '1',
        status: 'off',
        link: '/fundaciones'
    },
    {
        value: '2',
        status: 'on',
        link: '/fundaciones'
    },
    {
        value: '3',
        status: 'ok',
        link: '/fundaciones'
    },
    {
        value: '4',
        status: 'warnig',
        link: '/fundaciones'
    },
    {
        value: '5',
        status: 'error',
        link: '/fundaciones'
    }
],
[
    {
        value: '6',
        status: 'ok',
        link: '/fundaciones'
    },
    {
        value: '7',
        status: 'on',
        link: '/fundaciones'
    },
    {
        value: '8',
        status: 'ok',
        link: '/fundaciones'
    },
    {
        value: '9',
        status: 'ok',
        link: '/fundaciones'
    },
    {
        value: '10',
        status: 'off',
        link: '/fundaciones'
    }
],
[
    {
        value: '11',
        status: 'ok',
        link: '/fundaciones'
    },
    {
        value: '12',
        status: 'on',
        link: '/fundaciones'
    },
    {
        value: '13',
        status: 'warnig',
        link: '/fundaciones'
    },
    {
        value: '14',
        status: 'error',
        link: '/fundaciones'
    },
    {
        value: '15',
        status: 'on',
        link: '/fundaciones'
    }
],
[
    {
        value: '16',
        status: 'on',
        link: '/fundaciones'
    },
    {
        value: '17',
        status: 'on',
        link: '/fundaciones'
    },
    {
        value: '18',
        status: 'off',
        link: '/fundaciones'
    },
    {
        value: '19',
        status: 'on',
        link: '/fundaciones'
    },
    {
        value: '20',
        status: 'ok',
        link: '/fundaciones'
    }
],
[
    {
        value: '21',
        status: 'on',
        link: '/fundaciones'
    },
    {
        value: '22',
        status: 'on',
        link: '/fundaciones'
    },
    {
        value: '23',
        status: 'off',
        link: '/fundaciones'
    },
    {
        value: '24',
        status: 'on',
        link: '/fundaciones'
    },
    {
        value: '25',
        status: 'ok',
        link: '/fundaciones'
    }
]];

export default function GridStatusExample() {
    return (
        <GridStatus data={data} id='grid-default'/>
    );
}`;

const code2 = `
import GridStatus from "../../../components/elements/GridStatus";

const data = [
    [
        {
            value: '1',
            status: 'off',
            link: '/fundaciones'
        },
        {
            value: '2',
            status: 'on',
            link: '/fundaciones'
        },
        {
            value: '3',
            status: 'ok',
            link: '/fundaciones'
        },
        {
            value: '4',
            status: 'warnig',
            link: '/fundaciones'
        },
        {
            value: '5',
            status: 'error',
            link: '/fundaciones'
        }
    ],
    [
        {
            value: '6',
            status: 'ok',
            link: '/fundaciones'
        },
        {
            value: '7',
            status: 'on',
            link: '/fundaciones'
        },
        {
            value: '8',
            status: 'ok',
            link: '/fundaciones'
        },
        {
            value: '9',
            status: 'ok',
            link: '/fundaciones'
        },
        {
            value: '10',
            status: 'off',
            link: '/fundaciones'
        }
    ]
];


export default function GridStatusExample() {
    return (
        <GridStatus data={data} textAlign='left' id='grid-left' />
    );
}`;

const code3 = `
import GridStatus from "../../../components/elements/GridStatus";

const data = [
    [
        {
            value: '1',
            status: 'off',
            link: '/fundaciones'
        },
        {
            value: '2',
            status: 'on',
            link: '/fundaciones'
        },
        {
            value: '3',
            status: 'ok',
            link: '/fundaciones'
        },
        {
            value: '4',
            status: 'warnig',
            link: '/fundaciones'
        },
        {
            value: '5',
            status: 'error',
            link: '/fundaciones'
        }
    ],
    [
        {
            value: '6',
            status: 'ok',
            link: '/fundaciones'
        },
        {
            value: '7',
            status: 'on',
            link: '/fundaciones'
        },
        {
            value: '8',
            status: 'ok',
            link: '/fundaciones'
        },
        {
            value: '9',
            status: 'ok',
            link: '/fundaciones'
        },
        {
            value: '10',
            status: 'off',
            link: '/fundaciones'
        }
    ]
];


export default function GridStatusExample() {
    return (
        <GridStatus data={data} textAlign='right' id='grid-right' />
    );
}`;

const code4 = `
import GridStatus from "../../../components/elements/GridStatus";

const data = [
    [
        {
            value: 'Server apagado',
            status: 'off',
            link: '/fundaciones'
        },
        {
            value: 'Server encendido',
            status: 'on',
            link: '/fundaciones'
        },
        {
            value: 'Server Funcionando',
            status: 'ok',
            link: '/fundaciones'
        },
        {
            value: 'Server warnig 500',
            status: 'warnig',
            link: '/fundaciones'
        },
        {
            value: 'Error server 404',
            status: 'error',
            link: '/fundaciones'
        }
    ],
    [
        {
            value: 'Server Funcionando',
            status: 'ok',
            link: '/fundaciones'
        },
        {
            value: 'Server encendido',
            status: 'on',
            link: '/fundaciones'
        },
        {
            value: 'Server Funcionando',
            status: 'ok',
            link: '/fundaciones'
        },
        {
            value: 'Server Funcionando',
            status: 'ok',
            link: '/fundaciones'
        },
        {
            value: 'Server apagado',
            status: 'off',
            link: '/fundaciones'
        }
    ]
];


export default function GridStatusExample() {
    return (
        <GridStatus data={data} textAlign='justify' id='grid-justify' />
    );
}`;

export default function GridStatusExample() {
    return (
        <Main>
            <BoxCode title='Grid Status' code={code}>
                <GridStatus data={data} id='grid-default'/>
            </BoxCode>

            <BoxCode title='Grid Status Left' code={code2}>
                <GridStatus data={data2} textAlign='left' id='grid-left' />
            </BoxCode>

            <BoxCode title='Grid Status Right' code={code3}>
                <GridStatus data={data2} textAlign='right' id='grid-right' />
            </BoxCode>

            <BoxCode title='Grid Status Justify' code={code4}>
                <GridStatus data={data3} textAlign='justify' id='grid-justify' />
            </BoxCode>
        </Main>
    );
}