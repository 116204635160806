// Router
import {Link, useLocation} from "react-router-dom";

export default function Sidebar() {
    const {pathname} = useLocation();

    return (
        <div className="sidebar fixed">
            <div className="wrapper p40 flex col scroll-y">
                <h5>Fundaciones</h5>
                <ul className="ls-no flex col">
                    <li className={`p5 ${pathname.includes("colores") ? "selected" : ""}`}>
                        <Link to="/fundaciones/colores">Colores</Link>
                    </li>
                    <li className={`p5 ${pathname.includes("tipografia") ? "selected" : ""}`}>
                        <Link to="/fundaciones/tipografia">Tipografía</Link>
                    </li>
                </ul>
                <h5>Componentes</h5>
                <ul className="ls-no flex col">
                    <li className={`p5 ${pathname.includes("botones") ? "selected" : ""}`}>
                        <Link to="/componentes/botones">Botones</Link>
                    </li>
                    <li className={`p5 ${pathname.includes("cards") ? "selected" : ""}`}>
                        <Link to="/componentes/cards">Cards</Link>
                    </li>
                    <li className={`p5 ${pathname.includes("navbar") ? "selected" : ""}`}>
                        <Link to="/componentes/navbar">Navbar</Link>
                    </li>

                    <li className={`p5 ${pathname.includes("sidebar") ? "selected" : ""}`}>
                        <Link to="/componentes/sidebar">Sidebar</Link>
                    </li>
                    <li className={`p5 ${pathname.includes("dropdown") ? "selected" : ""}`}>
                        <Link to="/componentes/dropdown">Dropdown</Link>
                    </li>

                    <li className={ `p5 ${ pathname.includes('listas') ? 'selected' : '' }` }>
                        <Link to='/componentes/listas'>Listas</Link>
                    </li>

                    <li className={ `p5 ${ pathname.includes('breadcrumb') ? 'selected' : '' }` }>
                        <Link to='/componentes/breadcrumb'>Breadcrumb</Link>
                    </li>

                    <li className={ `p5 ${ pathname.includes('tablas') ? 'selected' : '' }` }>
                        <Link to='/componentes/tablas'>Tablas</Link>
                    </li>
                    <li className={ `p5 ${ pathname.includes('tags') ? 'selected' : '' }` }>
                        <Link to='/componentes/tags'>Tags</Link>
                    </li>
                    <li className={ `p5 ${ pathname.includes('listados') ? 'selected' : '' }` }>
                        <Link to='/componentes/listados'>Listados</Link>
                    </li>
                    <li className={ `p5 ${ pathname.includes('inputs') ? 'selected' : '' }` }>
                        <Link to='/componentes/inputs'>Inputs</Link>
                    </li>
                    <li className={ `p5 ${ pathname.includes('progress-bar') ? 'selected' : '' }` }>
                        <Link to='/componentes/progress-bar'>Progress bar</Link>
                    </li>
                    <li className={ `p5 ${ pathname.includes('switch') ? 'selected' : '' }` }>
                        <Link to='/componentes/switch'>Switch</Link>
                    </li>
                    <li className={ `p5 ${ pathname.includes('modal') ? 'selected' : '' }` }>
                        <Link to="/componentes/modal">Modal</Link>
                    </li>
                    <li className={ `p5 ${ pathname.includes('uptime') ? 'selected' : '' }` }>
                        <Link to='/componentes/uptime'>Uptime</Link>
                    </li>
                    <li className={ `p5 ${ pathname.includes('tooltip') ? 'selected' : '' }` }>
                        <Link to='/componentes/tooltip'>Tooltip</Link>
                    </li>
                    <li className={ `p5 ${ pathname.includes('grid-status') ? 'selected' : '' }` }>
                        <Link to='/componentes/grid-status'>Grid Status</Link>
                    </li>

                    {/* ELEMENTOS CLIENTE -> GENERALES  */}
                    {/* <li className={`p5`}>
                        <Link to="#">Dialog Boxes</Link>
                    </li>
                    <li className={`p5`}>
                        <Link to="#">Feedback</Link>
                    </li>
                    <li className={`p5`}>
                        <Link to="#">Form Elements</Link>
                    </li>
                    <li className={`p5`}>
                        <Link to="#">Navbar logo</Link>
                    </li>
                    <li className={`p5`}>
                        <Link to="#">Navbar menú hamburgesa</Link>
                    </li>
                    <li className={`p5`}>
                        <Link to="#">Navbar icon return</Link>
                    </li>
                    <li className={`p5`}>
                        <Link to="#">Card</Link>
                    </li>
                    <li className={`p5`}>
                        <Link to="#">Category tags</Link>
                    </li>
                    <li className={`p5`}>
                        <Link to="#">Enlaces</Link>
                    </li>
                    <li className={`p5`}>
                        <Link to="#">Boton primario</Link>
                    </li>
                    <li className={`p5`}>
                        <Link to="#">Modal</Link>
                    </li>
                    <li className={`p5`}>
                        <Link to="#">Modal Header</Link>
                    </li>
                    <li className={`p5`}>
                        <Link to="#">Modal titulo</Link>
                    </li>
                    <li className={`p5`}>
                        <Link to="#">Modal body</Link>
                    </li>
                    <li className={`p5`}>
                        <Link to="#">Boton icon</Link>
                    </li> */}
                    <li className={`p5`}>
                        <Link to="#">... </Link>
                    </li>
                </ul>
            </div>
        </div>
    );
}
