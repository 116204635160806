import Main from "../../../components/layout/Main";

export default function Tipografia() {
    return (
        <Main>
            <div className='section typography'>
                <h5 className='c-green'>Fundaciones</h5>
                <h2>Tipografía</h2>

                <div className='sampler'>
                    <h1>Esto es Gotham Narrow, la tipografía de la marca.</h1>
                    <p>Gotham celebra las letras atractivas y sin pretensiones de la ciudad. Nueva York está llena de este tipo de letras, sans serif hechas a mano que comparten una estructura subyacente común, la idea de un ingeniero de "letras básicas" que trasciende tanto las características de sus materiales como los gestos de sus creadores.</p>

                    <h3 className="typo2">Helvetica Neue es la tipografía de soporte.</h3>
                    <p>Helvetica Neue ( pronunciación alemana: [ˈnɔʏ̯ə] ) es una reelaboración del tipo de letra con un conjunto de alturas y anchuras más unificado estructuralmente. Otros cambios incluyen legibilidad mejorada, signos de puntuación más gruesos y mayor espacio entre los números.</p>
                </div>

                <ul className="flex col mt40 typo-list">
                    <li className="flex row g10 align-c just-sb">
                        <h1>Heading One</h1>
                        <p>44px Gotham Narrow · Black</p>
                    </li>
                    <li className="flex row g10 align-c just-sb">
                        <h2>Heading Two</h2>
                        <p>36px Gotham Narrow · Bold</p>
                    </li>
                    <li className="flex row g10 align-c just-sb">
                        <h3>Heading Three</h3>
                        <p>28px Helvetica Neue · Medium</p>
                    </li>
                    <li className="flex row g10 align-c just-sb">
                        <h4>Heading Four</h4>
                        <p>18px Helvetica Neue · Medium</p>
                    </li>
                    <li className="flex row g10 align-c just-sb">
                        <h5>Heading Five</h5>
                        <p>16px Helvetica Neue · Bold</p>
                    </li>
                    <li className="flex row g10 align-c just-sb">
                        <p><b>Body Bold</b></p>
                        <p>16px Helvetica Neue · Bold</p>
                    </li>
                    <li className="flex row g10 align-c just-sb">
                        <p>Body</p>
                        <p>16px Helvetica Neue · Regular</p>
                    </li>
                    <li className="flex row g10 align-c just-sb">
                        <p className="button" style={{ background: 'transparent' }}>Button & Meta</p>
                        <p>16px Helvetica Neue · Medium</p>
                    </li>
                </ul>
            </div>
        </Main>
    );
}
