import { useEffect } from "react";
import { useState } from "react";
import Input from "./Input";

export default function Dropdown({items = [],search,placeholder = "Selecciona una opción", onSelectOption}) {
    const [open, setOpen] = useState(false);
    const [selected, setSelected] = useState([]);

    // console.log(selected);
    const [searchValue, setSearchValue] = useState('');

    const [filteredItems, setFilteredItems] = useState(items);

    useEffect(() => {
        setFilteredItems(items);
    }, [items]);

    useEffect(() => {
        if (searchValue.length > 0) {
            const filtered = items.filter(item => item.value.toLowerCase().includes(searchValue.toLowerCase()));
            setFilteredItems(filtered);
        } else {
            setFilteredItems(items);
        }
    }, [searchValue]);

    useEffect(() => {
        if (open && search) {
           // set focus on input element when dropdown is opened
              document.getElementById('dropdown-search').focus();
        }
    }, [open]);


    //close dropdown when clicked outside
    useEffect(() => {
        const closeDropdown = (e) => {
            if (e.target.closest('.dropdown') === null) {
                setOpen(false);
            }
        }
        document.addEventListener('click', closeDropdown);
        return () => document.removeEventListener('click', closeDropdown);
    }, []);


    //close dropdown when escape key is pressed
    useEffect(() => {
        const closeDropdown = (e) => {
            if (e.key === 'Escape' || e.key === 'Esc' || e.keyCode === 27 || e.key === 'Tab') {
                setOpen(false);
            }
        }
        document.addEventListener('keydown', closeDropdown);
        return () => document.removeEventListener('keydown', closeDropdown);
    }, []);



    const toggleDropdown = () => {
        var targetDiv = document.getElementsByClassName("dropdown-content");

        if (targetDiv.length > 0) {
            for (var i = 0; i < targetDiv.length; i++) {
                //console.log(targetDiv[i]);
                if(targetDiv[i].style.display === "block"){
                    targetDiv[i].style.display = "none";
                }
                if(i===targetDiv.length-1){
                    setOpen(!open);
                }
            }
        
        }else{
            setOpen(!open);
            
        }
    }

    


    return (
        <>
        <div className="dropdown" onClick={() => toggleDropdown()}>
            <input type="hidden" name="dropdown" value={selected ? selected.id : ''}/>
            <span>{selected?.value || placeholder}</span>
            <div className="arrow" style={ open ? {transform: 'rotate(180deg) translateY(50%)'} : {}}></div>
            <div className="dropdown-content" style={open? {display: 'block'} : {display: 'none'}}>
            {search && <Input type="search" id="dropdown-search"  placeholder="Buscar..." onChange={e => setSearchValue(e.target.value)} />}

            {filteredItems ? filteredItems.map((item, index) => (
                
                <div className="dropdown-item" key={index} onClick={() => { setSelected(item); setOpen(false); onSelectOption(item) }} >
                    <span>{item.value}</span>
                </div>
            )) :  
                <div className="dropdown-item">No data found</div>
            }

            </div>
           


        </div>

        
       
    </>

    );

}