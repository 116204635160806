
// Layout
import Main from "../../../components/layout/Main";

// Class
import BoxCode from "../../../components/elements/BoxCode";

// Component
import Card from "../../../components/elements/Card";

export default function Cards() {

    const code1 = `
import Card from "../../../components/elements/Card";

export default function CardsExample() {
    return (
        <Card>
                        <p>Card Sencilla sin divisiones</p>
                </Card>
    );
}`;

    const code2 = `
import Card from "../../../components/elements/Card";

export default function CardsExample() {
    return (
        <>
                        <Card className="test" dir="rows">
                                <p>Card Sencilla con rows</p>
                                <p>Card Sencilla con rows</p>
                                <p>Card Sencilla con rows</p>
                        </Card>

                        <Card className="test" dir="rows" padding="int">
                                <p>Card Sencilla con rows</p>
                                <p>Card Sencilla con rows</p>
                                <p>Card Sencilla con rows</p>
                        </Card>
                </>
    );
}`;

    const code3 = `
import Card from "../../../components/elements/Card";

export default function CardsExample() {
    return (
        <>
                        <Card className="test" dir="cols">
                                <p>Card Sencilla con rows</p>
                                <p>Card Sencilla con rows</p>
                                <p>Card Sencilla con rows</p>
                        </Card>

                        <Card className={"test"} dir="cols" padding="int">
                                <p>Card Sencilla con rows</p>
                                <p>Card Sencilla con rows</p>
                                <p>Card Sencilla con rows</p>
                        </Card>
                </>
    );
}`;

    return (
        <Main>
            <div className="section elementos">
                <h5 className="c-green">Componentes</h5>
                <h2>Elementos</h2>

                <BoxCode title='Cards' classBox='gray6' code={code1}>
                    <Card>
                        <p>Card Sencilla sin divisiones</p>
                    </Card>
                </BoxCode>


                <BoxCode title='Cards con contenido en filas con padding interno y externo' classBox='gray6' code={code2}>
                    <Card className="test" dir="rows">
                        <p>Card Sencilla con rows</p>
                        <p>Card Sencilla con rows</p>
                        <p>Card Sencilla con rows</p>
                    </Card>

                    <Card className="test" dir="rows" padding="int">
                        <p>Card Sencilla con rows</p>
                        <p>Card Sencilla con rows</p>
                        <p>Card Sencilla con rows</p>
                    </Card>
                </BoxCode>


                <BoxCode title='Cards con contenido en columnas con padding interno y externo' classBox='gray6' code={code3}>
                    <Card className="test" dir="cols">
                        <p>Card Sencilla con rows</p>
                        <p>Card Sencilla con rows</p>
                        <p>Card Sencilla con rows</p>
                    </Card>

                    <Card className="test" dir="cols" padding="int">
                        <p>Card Sencilla con rows</p>
                        <p>Card Sencilla con rows</p>
                        <p>Card Sencilla con rows</p>
                    </Card>
                </BoxCode>
            </div>
        </Main>
    );
}
