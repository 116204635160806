import Nav from "../components/elements/Nav";

const link = [{
    title: 'Fundaciones',
    link: '/fundaciones',
    itemClass: '',
    icon: ''
},
{
    title: 'Componentes',
    link: '/componentes',
    itemClass: '',
    icon: ''
}
];

export default function Header() {
    return <Nav links={link} />;
}
