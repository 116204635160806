// React
import { Routes, Route, Navigate } from 'react-router-dom';
import ComponentesRoutes from '../pages/componentes/routes/ComponentesRoutes';

// Routes
import FundacionesRoutes from '../pages/fundaciones/routes/FundacionesRoutes';

export default function AppRouter() {

    return (
        <Routes>
            <Route path='fundaciones/*' element={<FundacionesRoutes /> } />
            <Route path='componentes/*' element={<ComponentesRoutes /> } />

            <Route path='/' element={<Navigate to={'fundaciones'} />} />
        </Routes>
    );
}