

// Layout
import Main from "../../../components/layout/Main";

// Component
import BoxCode from "../../../components/elements/BoxCode";
import Modal from "../../../components/elements/Modal";
import Button from "../../../components/elements/Button";

// React
import { useState } from "react";

const code = `
import Modal from "../../../components/elements/Modal";
import Button from "../../../components/elements/Button";

export default function ModalExample() {
    return (
        <>
                    <Button onClick={() => setIsOpen(!isOpen)}>Open Modal</Button>
                    <Modal open={isOpen} onClose={() => setIsOpen(false)} size='600'>
                            <h2>Modal</h2>
                            <p>Modal de prueba</p>
                    </Modal>
            </>
    );
}`;

export default function ModalExample() {

    const [isOpen, setIsOpen] = useState(false);

    return (
        <Main>
            <div className="section elementos">
                <h5 className="c-green">Componentes</h5>
                <h2>Elementos</h2>

                <BoxCode title='Modal' classBox='gray6' code={code}>
                    <Button onClick={() => setIsOpen(!isOpen)}>Open Modal</Button>
                    <Modal open={isOpen} onClose={() => setIsOpen(false)} size='600'>
                        <h2>Modal</h2>
                        <p>Modal de prueba</p>
                    </Modal>
                </BoxCode>
            </div>
        </Main>
    );
}