export default function Breadcrumb({ children }) {
    return <div className="breadcrumb">
        {
            children.map((child, index) => {
                return <> 
                {index >0 && <div className="arrow" style={{transform: 'rotate(270deg)',marginTop:'0px'}}></div>}
                <div key={index} >
                    {child}
                </div>
                </>
            })
        }
        
        </div>;
}
