
// Layout
import Main from "../../../components/layout/Main";

// Component
import BoxCode from "../../../components/elements/BoxCode";
import Input from "../../../components/elements/Input";


export default function Inputs() {

    const code1 = `
import Input from "../../../components/elements/Input";

export default function InputExample() {
    return (
        <Input type='search' />
    );
}`;

    const code2 = `
import Input from "../../../components/elements/Input";

export default function InputExample() {
    return (
        <Input type='number' value={Math.floor(Math.random() * 10000)} />
    );
}`;

    const code3 = `
import Input from "../../../components/elements/Input";

export default function InputExample() {
    return (
        <Input type='number' value={Math.floor(Math.random() * 10000)} showArrow={true} />
    );
}`;

    const code4 = `
import Input from "../../../components/elements/Input";

export default function InputExample() {
    return (
        <Input type='date' value={new Date().toISOString().slice(0, 10)} />
    );
}`;

    const code5 = `
    import Input from "../../../components/elements/Input";
    
    export default function InputExample() {
        return (
            <Input type='time' value={new Date().toISOString().slice(11, 16)} />
        );
    }`;

    const code6 = `
import Input from "../../../components/elements/Input";

export default function InputExample() {
    return (
        <Input type='datetime-local' value={new Date().toISOString().slice(0, 16)} />
    );
}`;


    return (
        <Main>
            <div className="section elementos">
                <h5 className="c-green">Componentes</h5>
                <h2>Elementos</h2>

                <BoxCode title='Input Search' classBox='gray6' code={code1}>
                    <Input type='search' />
                </BoxCode>


                <BoxCode title='Input Number' classBox='gray6' code={code2}>
                    <Input type='number' value={Math.floor(Math.random() * 10000)} />
                </BoxCode>


                <BoxCode title='Input Number + Spinner' classBox='gray6' code={code3}>
                    <Input type='number' value={Math.floor(Math.random() * 10000)} showArrow={true} />
                </BoxCode>


                <BoxCode title='Input Date' classBox='gray6' code={code4}>
                    <Input type='date' value={new Date().toISOString().slice(0, 10)} />
                </BoxCode>


                <BoxCode title='Input Time' classBox='gray6' code={code5}>
                    <Input type='time' value={new Date().toISOString().slice(11, 16)} />
                </BoxCode>


                <BoxCode title='Input Date Time' classBox='gray6' code={code6}>
                    <Input type='datetime-local' value={new Date().toISOString().slice(0, 16)} />
                </BoxCode>
            </div>
        </Main>
    );
}