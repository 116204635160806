import { useEffect, useRef, useState } from "react";

export default function ProgressBar({ color, height, type, isSuscced, isError, time }) {

    const [load, setLoad] = useState(0);

    const progress = useRef(null);

    useEffect(() => {
        if (color != undefined && color != '') {
            !color.includes('#') && !color.includes('rgb') ? progress.current.classList.add(color) : progress.current.style.backgroundColor = color;
        }
    }, [])

    useEffect(() => {
        if (isError != undefined && isError != false) {
            progress.current.style.backgroundColor = '#f00'
        } else {
            if (isSuscced != undefined && isSuscced != false) {
                setLoad(100);
            } else {
                if (load < 95) {
                    loading();  
                }   
            } 
        }  
    }, [load, isSuscced])
    
    function loading() {
        setTimeout(() => {
            setLoad(load + Math.floor(Math.random() * (5 - 1) + 1));
        }, time ?? 100);
    }

    if (type != undefined) {
        
        switch (type.toUpperCase()) {
            case 'PORCENTAJE':
                return (
                    <div className="progress-container">
                        {isError != undefined && isError != false ? <p>Error</p> : <></>}
                        <div className="linea-progress" style={{ height: `${height}px` }}>
                            <span className="back"></span>
                            <span ref={progress} className="progress" style={{ width: `${load <= 100 ? load  : 100 }%` }}></span>
                        </div>
                        <p>{load <= 100 ? load  : 100 } %</p>
                    </div>
                );
        
            default:
    
        }

    } else {
        return (
            <div className="progress-container">
                <div className="linea-progress" style={{ height: `${height}px` }}>
                    <span className="back"></span>
                    <span ref={progress} className="progress animate"></span>
                </div>
            </div>
        );
    }

}