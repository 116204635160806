import { useEffect, useState } from "react";
import Dropdown from "../Dropdown";
import Col from "./Col";
import Row from "./Row";


export default function Table({ children, className, pag }) {
    
    const [tableHeader, setTableHeader] = useState([]);
    const [tableBody, setTableBody] = useState([]);
    const [filtros, setFiltros] = useState([]);
    const [isOrder, setIsOrder] = useState(false);

    const [indexPag, setIndexPag] = useState(1);
    const [pagMax, setPagMax] = useState(pag);
    const [pagMin, setPagMin] = useState(0);
    const [sizeContentTable, setSizeContentTable] = useState(0);
    const [sizeTable, setSizeTable] = useState(0);

    const [breakPoints, setBreakPoints] = useState(0);
    const [breakPoints2, setBreakPoints2] = useState([]);

    const header = Array.isArray(children) ? children.filter(child => child.props.type?.toUpperCase() == 'HEADER')[0] : [];
    const body = Array.isArray(children) ? children.filter(child => child.props.type?.toUpperCase() != 'HEADER') : [];

    useEffect(() => {
        obtenerHeader(header);
    }, [tableBody])

    useEffect(() => {
        obtenerBody(body, header);
        if (body.length > 0) {
            obtenerFiltros(header, body);    
        }
    }, [])

    useEffect(() => {
        new ResizeObserver(([e]) => setSizeTable(e.target.clientWidth)).observe(document.getElementById('content_table'));
        new ResizeObserver(([e]) => {setSizeContentTable(e.target.clientWidth)}).observe(document.getElementById('table-wui'));
        setBreakPoints2([document.getElementById('content_table')?.clientWidth]);
    }, [])

    useEffect(() => {
        
        if (sizeTable < sizeContentTable) {
            let priority = document.querySelectorAll(".table-header td.priority:not(.none)");
            let priorityLow = document.querySelectorAll(".table-header td.priority-baja:not(.none)");
            let priorityMedium = document.querySelectorAll(".table-header td.priority-media:not(.none)");
            let selectorClass = '';

            if (priority.length > 0) {
                selectorClass = priority[priority.length - 1].classList[0];
            } else if (priorityLow.length > 0) {
                selectorClass = priorityLow[priorityLow.length - 1].classList[0];
            } else if (priorityMedium.length > 0) {
                selectorClass = priorityMedium[priorityMedium.length - 1].classList[0];
            } else {
                return
            }

            if (breakPoints > 0) {
                document.querySelectorAll(`.${selectorClass}`).forEach(nodo => nodo.classList.add('none'));

                if (document.querySelectorAll(".table-header td.priority.none").length > 1) {
                    setBreakPoints2(breakPoints2 => [...breakPoints2, breakPoints]);
                }
            }
            setBreakPoints(sizeContentTable);

        } else {

            let priority = document.querySelectorAll(".table-header td.priority.none");
            let priorityLow = document.querySelectorAll(".table-header td.priority-baja.none");
            let priorityMedium = document.querySelectorAll(".table-header td.priority-media.none");
            let selectorClass = null;

            if (priorityMedium.length > 0) {
                if (sizeContentTable >= breakPoints2[breakPoints2.length - 1]) {
                    selectorClass = priorityMedium[0].classList[0];

                    if (breakPoints2.length - 1 > 0) {
                        setBreakPoints2(breakPoints2.slice(0, breakPoints2.length - 1));   
                    }
                }
            } else if (priorityLow.length > 0) {
                if (sizeContentTable >= breakPoints2[breakPoints2.length - 1]) {
                    selectorClass = priorityLow[0].classList[0];

                    if (breakPoints2.length - 1 > 0) {
                        setBreakPoints2(breakPoints2.slice(0, breakPoints2.length - 1));   
                    }
                }
            } else  if (priority.length > 0) {
                if (sizeContentTable >= breakPoints2[breakPoints2.length - 1]) {
                    selectorClass = priority[0].classList[0];

                    if (breakPoints2.length - 1 > 0) {
                        setBreakPoints2(breakPoints2.slice(0, breakPoints2.length - 1));   
                    }
                }   
            }

            document.querySelectorAll(`.${selectorClass}`).forEach(nodo => nodo.classList.remove('none'));

        }
    }, [sizeTable])
    
    

    function obtenerHeader(header) {
        setTableHeader(
            <Row type={header.props.type}>
                 {header.props.children.map((el, index) => {
                    return <Col className={`${getClass(el.props.priority)} ${el.props.className}`} key={index} size={el.props.size} priority={el.props.priority} order={el.props.order} actionOrder={() => handleOrder(index)}>{el.props.children}</Col>;
                 })}
            </Row>
        );
    }

    let priorityUndefined = 0;
    function getClass(priority) {

        switch (priority) {
            case 'baja':
                return `priority-baja-${++priorityUndefined} priority-baja`
            case 'media':
                return `priority-media-${++priorityUndefined} priority-media`
            case 'alta':
                return `priority-alta-${++priorityUndefined} priority-alta`
        
            default:
                return `priority-${++priorityUndefined} priority`
        }
    }

    function obtenerBody(body, header) {
        setTableBody(
            body.map((row, index) => {
                priorityUndefined = 0
                let data = [];
                let head = [];
                let type = [];

                row.props.children.forEach((el, idx) => {
                    data.push(el.props.children);
                    head.push(header.props.children[idx]?.props.children);
                    type.push(header.props.children[idx]?.props.type);
                });

                return (
                    <Row type='body' key={index} head={head} data={data}>
                        {row.props.children.map((el, idx) => {
                            return <Col key={idx} type={type[idx]} priority={`${header.props.children[idx]?.props.priority}`} className={`${getClass(header.props.children[idx]?.props.priority)}`} max={header.props.children[idx]?.props.max}>{el.props.children}</Col>
                        })}
                    </Row>
                )
            })
        )
    }

    function obtenerFiltros(header, body) {
        setFiltros([])
        header.props.children.forEach((el, index) => {

            let [isFilter, type, placeholder] = el.props.filter != undefined ? el.props.filter : [];

            if (isFilter) {
                if (type == 'text') {
                    setFiltros(filtros => [...filtros,
                        <div className="flex col">
                            <label htmlFor="">{placeholder || el.props.children}</label>
                            <input type="text" placeholder={placeholder} onChange={({target}) => handleFilter(target.value, index)} />
                        </div>
                    ]);
                } else {
                    let filterData = [];
                    let data = [];
    
                    body.forEach(el => {
                        if (!data.includes(el.props.children[index].props.children)) {
                            data.push(el.props.children[index].props.children);
                        }
                    });
                    
                    data.forEach((el, index) => {
                        filterData.push({value: el, id: index});
                    });
    
                    setFiltros(filtros => [...filtros, 
                        <div className="flex col">
                            <label htmlFor="">{placeholder || el.props.children}</label>
                            <Dropdown items={filterData} onSelectOption={({value}) => handleFilter(value, index)}/>
                        </div>
                    ]);
                }
            }
         })
    }

    function handleOrder(index) {
        obtenerBody(
            tableBody.sort((a, b) => {
                if (a.props.children[index].props.children < b.props.children[index].props.children) {
                    return isOrder ? 1 : -1;
                }
    
                if (a.props.children[index].props.children > b.props.children[index].props.children) {
                    return isOrder ? -1 : 1;
                }
                
                return 0;
            }), header
        );

        setIsOrder(!isOrder);
    }

    function handleFilter(value, index) {
        value == '' ? obtenerBody(body, header) : obtenerBody(body.filter(body => body.props.children[index].props.children.toUpperCase().includes(value.toUpperCase())), header);
    }

    function prev() {
        setIndexPag(indexPag - 1)
        setPagMax(pagMax - pag);
        setPagMin(pagMin - pag);
    }

    function next() {
        setIndexPag(indexPag + 1)
        setPagMax(pagMax + pag);
        setPagMin(pagMax);
    }

    function handleItem(index) {
        setIndexPag(index)
        setPagMax(pag * index);
        setPagMin(pag * index - pag);
    }

    function getItem() {
        let items = [];

        for (let i = 1; i <= Math.ceil(tableBody.length / pag); i++) {
            items.push(<li className={`${indexPag == i ? 'active' : ''}`} onClick={() => handleItem(i)}>{i}</li>)
        }
        
        return items
    }

    return (
        <div className="content-table" id="content_table">
            {filtros.length > 0 ? <div className="filter">{filtros}</div> : <></>}
            <table id="table-wui" className={`table ${className !== undefined ? className : ''}`}>
                {tableHeader}
                <tbody className="table-body">{tableBody.slice(pagMin, pagMax)}</tbody>
            </table>

            {pag != undefined ?
            <div className="pagination">
                <div className={`prev ${indexPag == 1 ? 'disabled' : ''}`} onClick={() => prev()}>
                    <svg stroke="currentColor" fill="currentColor" strokeWidth="0" viewBox="0 0 320 512" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg">
                        <path d="M34.52 239.03L228.87 44.69c9.37-9.37 24.57-9.37 33.94 0l22.67 22.67c9.36 9.36 9.37 24.52.04 33.9L131.49 256l154.02 154.75c9.34 9.38 9.32 24.54-.04 33.9l-22.67 22.67c-9.37 9.37-24.57 9.37-33.94 0L34.52 272.97c-9.37-9.37-9.37-24.57 0-33.94z"></path>
                    </svg>
                </div>
                <div className="pag">
                    <ul>{getItem()}</ul>
                </div>
                <div className={`next ${indexPag == Math.ceil(tableBody.length / pag) ? 'disabled' : ''}`} onClick={() => next()}>
                    <svg stroke="currentColor" fill="currentColor" strokeWidth="0" viewBox="0 0 320 512" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg">
                        <path d="M285.476 272.971L91.132 467.314c-9.373 9.373-24.569 9.373-33.941 0l-22.667-22.667c-9.357-9.357-9.375-24.522-.04-33.901L188.505 256 34.484 101.255c-9.335-9.379-9.317-24.544.04-33.901l22.667-22.667c9.373-9.373 24.569-9.373 33.941 0L285.475 239.03c9.373 9.372 9.373 24.568.001 33.941z"></path>
                    </svg>
                </div>
            </div> : <></>}
        </div>
    );
}