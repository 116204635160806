import React, { Component } from "react";

import hljs from 'highlight.js';
import javascript from 'highlight.js/lib/languages/javascript';
import '../../../node_modules/highlight.js/styles/base16/apathy.css'
hljs.registerLanguage('javascript', javascript)


export class PreviewCode extends Component {
    
    constructor(props) {
        super(props);                
        this.state = { loaded: false };
        this.codeNode = React.createRef();
    }

    componentDidMount() {
        try {
            this.setState(
                () => { return { loaded: true }; },
                () => { this.highlight(); }
            );                
        } catch (e) {
            console.error(e);
        }
    }

    componentDidUpdate() {
        this.highlight();
    }

    highlight = () => {
       // this.codeNode && this.codeNode.current && hljs.highlightBlock(this.codeNode.current);
       this.codeNode && this.codeNode.current && hljs.highlightElement(this.codeNode.current);
    }

    render() {
        const { children } = this.props;
        const { loaded } = this.state;
        if (!loaded) return '';

        return (
            <pre>
                <code ref={this.codeNode} className="language-javascript">{children}</code>
            </pre>
        );
    }
}