// Components
import Header from "../Header";
import Sidebar from "../Sidebar";

export default function Main({ children }) {
    return (
        <>
            <Header />

            <main className='main w100 scroll-y'>
                <div className='wrapper flex row g40'>
                    <Sidebar />

                    <div className='content p40'>
                        { children }
                    </div>
                </div>
            </main>
        </>
    );
}