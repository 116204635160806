
// Layout
import Main from "../../../components/layout/Main";

// Component
import Dropdown from "../../../components/elements/Dropdown";
import BoxCode from "../../../components/elements/BoxCode";

const Items = [{ 
    value: "United States",
    id: 1 
},
{ 
    value: "Canada",
    id: 2 
},
{ 
    value: "México",
    id: 3 
},
{ 
    value: "Japan",
    id: 4 
},
{ 
    value: "China",
    id: 5 
},
{ 
    value: "India",
    id: 6 
},
{ 
    value: "Brazil",
    id: 7 
},
{ 
    value: "Argentina",
    id: 8 
},
{ 
    value: "Chile",
    id: 9 
},
{ 
    value: "Peru",
    id: 10 
}]


export default function DropdownExample() {

    const code1 = `
import Dropdown from "../../../components/elements/Dropdown";

const Items = [{ 
    value: "United States",
    id: 1 
},
{ 
    value: "Canada",
    id: 2 
},
{ 
    value: "México",
    id: 3 
},
{ 
    value: "Japan",
    id: 4 
},
{ 
    value: "China",
    id: 5 
},
{ 
    value: "India",
    id: 6 
},
{ 
    value: "Brazil",
    id: 7 
},
{ 
    value: "Argentina",
    id: 8 
},
{ 
    value: "Chile",
    id: 9 
},
{ 
    value: "Peru",
    id: 10 
}]

export default function DropdownExample() {
    return (
        <Dropdown placeholder="Selecciona un país" items={Items} />
    );
}`;

    const code2 = `
import Dropdown from "../../../components/elements/Dropdown";

const Items = [{ 
    value: "United States",
    id: 1 
},
{ 
    value: "Canada",
    id: 2 
},
{ 
    value: "México",
    id: 3 
},
{ 
    value: "Japan",
    id: 4 
},
{ 
    value: "China",
    id: 5 
},
{ 
    value: "India",
    id: 6 
},
{ 
    value: "Brazil",
    id: 7 
},
{ 
    value: "Argentina",
    id: 8 
},
{ 
    value: "Chile",
    id: 9 
},
{ 
    value: "Peru",
    id: 10 
}]

export default function DropdownExample() {
    return (
        <Dropdown placeholder="Selecciona un país" items={Items} search={true} />
    );
}`;

    return (
        <Main>
            <div className="section elementos">
                <h5 className="c-green">Componentes</h5>
                <h2>Elementos</h2>

                <BoxCode title='Dropdown' classBox='gray6' code={code1}>
                    <Dropdown placeholder="Selecciona un país" items={Items} />
                </BoxCode>


                <BoxCode title='Dropdown Search' classBox='gray6' code={code2}>
                    <Dropdown placeholder="Selecciona un país" items={Items} search={true} />
                </BoxCode>
            </div>
        </Main>
    );
}