import React, {useState} from "react";
import { Link, useLocation } from "react-router-dom";

// Image
import Menu from '../../assets/imgs/menu.svg';

export default function Nav({ navClass, logo, links }) {
    
    const [navToggle, setNavToggle] = useState(false);

    const { pathname } = useLocation();

    return (
        <nav className={`${navClass} nav ${ navToggle ? "on" : "" }`}>
            <div className="wrapper">

                {/* LOGO */}
                { logo != undefined ?
                <a href="#">
                    <img src={logo} width='100px' alt="Logo" />
                </a> :
                <a className="logo" href="#">
                    wui <span>beta</span>
                </a> }

                {/* LINKS */}
                { links !== undefined ?
                <ul className="nav-menu flex align-c just-sb ls-no g20">
                    { links.map((link, index) => {
                        return (
                            <li key={index}>
                                <Link className={pathname.includes(link.link) ? 'active' : ''} to={link.link}>{link.title}</Link>
                            </li>
                        );
                    }) }
                </ul> : <></>}

                {/* Menu */}
                {links !== undefined ?
                    navToggle ?
                    <svg className="icon" strokeWidth="0" viewBox="0 0 16 16" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg" onClick={() => { setNavToggle(!navToggle) }}>
                        <path fillRule="evenodd" clipRule="evenodd" d="M7.116 8l-4.558 4.558.884.884L8 8.884l4.558 4.558.884-.884L8.884 8l4.558-4.558-.884-.884L8 7.116 3.442 2.558l-.884.884L7.116 8z"></path>
                    </svg>
                    :
                    <svg className="icon hamburger" strokeWidth="0" viewBox="0 0 1024 1024" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg" onClick={() => { setNavToggle(!navToggle) }}>
                        <path d="M904 160H120c-4.4 0-8 3.6-8 8v64c0 4.4 3.6 8 8 8h784c4.4 0 8-3.6 8-8v-64c0-4.4-3.6-8-8-8zm0 624H120c-4.4 0-8 3.6-8 8v64c0 4.4 3.6 8 8 8h784c4.4 0 8-3.6 8-8v-64c0-4.4-3.6-8-8-8zm0-312H120c-4.4 0-8 3.6-8 8v64c0 4.4 3.6 8 8 8h784c4.4 0 8-3.6 8-8v-64c0-4.4-3.6-8-8-8z"></path>
                    </svg> : <></>
                    // <a className="hamburger" href="#" onClick={() => { setNavToggle(!navToggle) }} >
                    //     <img src={Menu} alt="Menu" />
                    // </a> : <></>
                }
            </div>
            {links !== undefined ?
            <div className={`navbar-menu ${!navToggle ? 'none' : 'flex'}`}>
                <ul>
                    {links.map((link, index) => {
                        return (
                            <li key={index}>
                                <Link className={pathname.includes(link.link) ? 'active' : ''} to={link.link}>{link.title}</Link>
                            </li>
                        );
                    })}
                </ul>
            </div> : <></>}
        </nav>
    );
}
