
export default function Uptime({title,porcentaje, items}) {
    
    return (
        items?.length > 0 ? (

        <div className="uptime">
            <div className="uptime-header">
                <div className="uptime-title">
                  <p>{title?? "Uptime"}</p>
                </div>

                <div className="uptime-subtitle">
                  <p>Uptime {porcentaje>0 ? porcentaje : 0}%</p>
                </div>

            </div>
            
            <div className="uptime-content">
                {items.map((item, index) => (
                    
                    <div className={`uptime-box ${item.status? item.status : 'grey'} tooltip`} key={index}>
                        <p className="tooltip-text">{item.name ??"" }: {item.tooltip ?? ""}</p>
                    </div>
                ))
                }
            </div>
        </div>) : <p>No hay items para mostrar</p>
    );
}