

// Layout
import BoxCode from "../../../components/elements/BoxCode";

// Component
import Main from "../../../components/layout/Main";

const code = `
export default function TooltipExample() {
    return (
        <>
                    <div className="tooltip tag">
                            <span>left</span>
                            <p className="tooltip-text left">left</p>
                    </div>
                    <div className="tooltip tag">
                            <span>top</span>
                            <p className="tooltip-text top">top</p>
                    </div>

                    <div className="tooltip tag">
                            <span>bottom</span>
                            <p className="tooltip-text bottom">bottom</p>
                    </div>
                    <div className="tooltip tag">
                            <span>right</span>
                            <p className="tooltip-text right">right</p>
                    </div>
            </>
    );
}
`;

export default function TooltipExample() {

    function handleTooltip({ target }) {

        const tooltipText = target.lastChild;
        const body = document.querySelector('body').clientWidth;
        const content = target.clientWidth + 250 + 120 + 50 + 250;

        console.log(content, body);
        if (content > body) {
            if (tooltipText.className?.includes('right')) {
                // tooltipText.classList.remove('right')
                tooltipText.classList.add('left')
                tooltipText.classList.add('ml100')
            } else if (tooltipText.className?.includes('left')) {
                // tooltipText.classList.remove('left')
                tooltipText.classList.add('right')
            }
        } else {
            if (tooltipText.className?.includes('right')) {
                tooltipText.classList.remove('left')
                tooltipText.classList.remove('ml100')
                // tooltipText.classList.add('right')  
            } else if (tooltipText.className?.includes('left')) {
                tooltipText.classList.remove('right')
                // tooltipText.classList.add('right')  
            }
        }
    }

    return(
        <Main>
            {/* <div className="tooltip tag" onMouseEnter={handleTooltip}>
                <span>right</span>
                <p className="tooltip-text right">right</p>
            </div> */}
            <BoxCode title='Tooltip' classBox='gray6' code={code}>
                <div className="tooltip tag">
                    <span>left</span>
                    <p className="tooltip-text left">left</p>
                </div>
                <div className="tooltip tag">
                    <span>top</span>
                    <p className="tooltip-text top">top</p>
                </div>

                <div className="tooltip tag">
                    <span>bottom</span>
                    <p className="tooltip-text bottom">bottom</p>
                </div>
                <div className="tooltip tag" onMouseEnter={handleTooltip}>
                    <span>right</span>
                    <p className="tooltip-text right">right</p>
                </div>
            </BoxCode>
        </Main>
    );
}