// Layout
import Main from "../../../components/layout/Main";
import { useState } from "react";

// Component
import BoxCode from "../../../components/elements/BoxCode";
import Uptime from "../../../components/elements/Uptime";
import { useEffect } from "react";

const code = `
import Uptime from "../../../components/elements/Uptime";
import { useState, useEffect } from "react";

export default function UptimeExample() {

    const [items, setItems] = useState([]);

    useEffect(() => {   
        let listaitems = [];

        for (let index = 1; index < 51; index++) {

            let status = Math.floor(Math.random() * 4);

            let item = {
                id: index,
                name: "Servidor "+index,
                status: status === 0 ? "green" : status === 1 ? "yellow" :  status === 2 ? "red" : "grey",
                tooltip: status === 0 ? "Operacional" : status === 1 ? "Intermitente" :  status === 2 ? "No operacional" : "Desconectado",
            }

            listaitems.push(item);
        }

        setItems(listaitems);
    }, [])

    return (
        <Uptime items={items ?? []} title="Server monitor" porcentaje={90} />
    );
}
`;

export default function UptimeExample() {

    const [items, setItems] = useState([]);

    useEffect(() => {   
        let listaitems=[];
        for (let index = 1; index < 51; index++) {

            let status = Math.floor(Math.random() * 4);



            let item = {
                id: index,
                name: "Servidor "+index,
                status: status === 0 ? "green" : status === 1 ? "yellow" :  status === 2 ? "red" : "grey",
                tooltip: status === 0 ? "Operacional" : status === 1 ? "Intermitente" :  status === 2 ? "No operacional" : "Desconectado",
            }
            listaitems.push(item);
        }
        setItems(listaitems);
    }, [])
    return (
        
        <Main>
            <div className="section elementos">
                <h5 className="c-green">Componentes</h5>
                <h2>Elementos</h2>

                <BoxCode title='Uptime' classBox='gray6' code={code}>
                    <Uptime items={items ?? []} title="Server monitor" porcentaje={90} />
                </BoxCode>
            </div>
        </Main>
    );
}